.home {
    overflow: hidden;
    padding-top: 50px;

    @include mq(sm) {
        padding-top: 90px;
    }

    &__hero {
        width: 100vw;
        //transform: skewX(-5deg) rotate(-5deg);
        overflow: hidden;
        position: relative;
        padding-bottom: 5vw;
        margin-bottom: -5.5vw;

        &__row  {
            padding: 2vw 0;
            width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include mq(sm) {
                align-items: flex-start;
            }

            &--bottom {
                background: $black;
                padding: 40px 0 70px;
                z-index: 5;
                position: relative;
                transform: skewX(-5deg) rotate(-5deg);
                margin-bottom: -20px;

                @include mq(sm) {
                    padding: 3vw 0;
                    margin-bottom: 0;
                }
            }

            &--top {
                z-index: 10;
                position: relative;
            }
        }

        &__title {
            //transform: skewX(5deg) rotate(5deg);
            width: 90%;
            margin-bottom: 20px;
            z-index: 10;

            @include mq(sm) {
                //margin-left: 18vw;
                width: 40%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__wrapper {
            width: 90%;
            padding-bottom: 20px;

            @include mq(sm) {
                //margin-left: 20vw;
                width: 30%;
                padding-bottom: 70px;
            }
        }

        &__text {
            color: $black;
            font-weight: 900;
            text-transform: uppercase;
            font-size: 13px;
            width: 85%;
            transform: skewX(-5deg) rotate(-5deg);

            @include mq(sm) {
                width: auto;
                font-size: 1.3vw;
            }

            span {
                color: $red;
            }
        }

        &__comment {
            position: relative;
            background: $red;
            width: auto;
            margin-bottom: 0;
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
            text-align: center;
            width: 90%;
            padding: 10px 20px;
            z-index: 10;

            @include mq(sm) {
                padding: .5vw 3vw;
                width: 30%;
                font-size: 1.05vw;
                //margin-left: 20vw;
            }
        }

        &__image {
            position: absolute;
            right: -30%;
            width: 80%;
            bottom: -70px;            

            @include mq(sm) {
                bottom: -8vw;
                width: 46%;
                left: 4vw;
                right: 0;
                margin-left: auto;
                margin-right: auto;
            }

            img {
                width: 100%;
                height: auto;
                transform: skewX(5deg) rotate(5deg);
            }
        }
    }

    &__title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        @include mq(sm) {
            margin-bottom: 2vw;
        }

        img {
            height: auto;
            transform: skewX(5deg) rotate(5deg);
            width: 90%;

            @include mq(sm) {
                width: 40%;
            }
        }

        &--participar {
            img {
                transform: skewX(0) rotate(0);
            }
        }

        &--premios {
            img {
                @include mq(sm) {
                    width: 20%;
                }
            }
        }
    }

    &__form {
        @include trans;
        background: $red;
        transform: skewX(0) rotate(0);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 40px 15px;
        margin-top: -5px;
        position: relative;
        z-index: 100;

        @include mq(sm) {
            margin-top: -3px;
            padding: 20px;
            position: absolute;
            right: calc(50% - 450px);
            top: 0%;
            transform: skewX(0) rotate(0);
            width: 28%;
        }

        @include mq(md) {
            right: calc(50% - 590px);
        }

        &__col-wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            transform: skewX(0) rotate(0);
            flex-direction: column;

            @include mq(sm) {
                flex-direction: row;
                transform: skewX(0) rotate(0);
            }
        }

        &__col {
            @include trans;
            display: none;

            &.-active {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;
                margin-bottom: 40px;

                @include mq(sm) {
                    //width: 50%;
                    margin-bottom: 0;
                }
            }
        }

        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 90%;

            @include mq(sm) {
                width: 100%;
            }
        }

        &__index,
        &__instructions {
            color: $white;
            font-weight: 700;
            text-transform: uppercase;
            margin: 0;
            text-align: left;
            font-size: 100px;
            line-height: 1.1;

            @include mq(sm) {
                font-size: 10vw;
            }

            a {
                color: $white;

                &:first-of-type {
                    margin-right: 20px;
                }
            }
        }

        &__instructions {
            font-size: 28px;

            span {
                font-size: 20px;
            }

            @include mq(sm) {
                font-size: 2vw;
            }

            .facebook {
                background: #3b5998;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: inline-block;
                line-height: 40px;
                text-align: center;
            }
        }

        &__container {
            display: block;
            //width: 90%;
            width: 100%;

            @include mq(sm) {
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            @include mq(sm) {
                margin-bottom: 10px;
            }

            &--checkbox {
                flex-direction: row;

                label {
                    margin-left: 10px;
                    text-decoration: underline;
                    font-weight: 300;
                    font-size: 12px;
                    text-transform: initial;
                }
            }
        }

        &__label {
            text-transform: uppercase;
            color: $white;
            margin: 0 0 5px 0;
            font-size: 12px;

            @include mq(sm) {
                margin: 0 0 5px 0;
                font-size: 12px;
            }

            a {
                color: $white;
            }
        }

        &__input {
            @include trans;

            border: none;
            height: 30px;
            padding: 0 1vw;
            color: $darkGray;

            @include mq(sm) {
                height: 30px;
            }

            &--textarea {
                height: 20vh;
                padding: 1vw;
            }

            &:focus {
                outline: none;
                transform: scale3d(1.05, 1.05, 1);
                box-shadow: 0 0 10px rgba(0,0,0,.3);
            }
        }

        &__buttons-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .divider {
                width: 1px;
                height: 70px;
                background: $white;
                margin: 0 10px;
            }
        }

        &__button {
            @include trans;

            border-radius: 5px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: $white;
            width: 20%;
            height: 70px;
            padding: 10px;
            cursor: pointer;
            
            @include mq(sm) {
                padding: 1vw 0;
                // height: 6vw;
            }

            &:focus {
                transform: scale3d(1.05, 1.05, 1.05);
            }
        }

        &__image {
            width: 20px;
            margin-bottom: 10px;

            @include mq(sm) {
                width: 1vw;
                margin-bottom: .3vw;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__file {
            position: absolute;
            left: -9999px;
        }

        &__text {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;

            @include mq(sm) {
                font-size: .7vw;
            }
        }

        &__submit-button {
            width: 100%;
            color: $white;
            background: $black;
            border: none;
            font-weight: 700;
            text-transform: uppercase;
            position: relative;
            height: 40px;
            line-height: 40px;
            
            @include mq(sm) {
                font-size: 1.2vw;
                height: 40px;
                line-height: 40px;
            }

            i {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;

                @include mq(sm) {
                    right: 1.5vw;
                }
            }
        }

        &__dots {
            transform: skewX(5deg) rotate(5deg);;

            @include mq(sm)  {
                margin: 2vw 0 0 0;
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            li {
                padding: 0 10px;

                @include mq(sm) {
                    padding: 0 .5vw;
                }
            }

            i {
                @include trans;

                color: $white;
                font-size: 14px;
                opacity: .5;
                display: block;

                @include mq(sm) {
                    font-size: 1vw;
                }

                &.-active {
                    font-size: 18px;
                    opacity: 1;
                }
            }
        }
    }

    &__participar {
        @include trans;
        padding: 40px 0;

        @include mq(sm) {
            padding: 4vw 0;
        }

        &__row {
            display: flex;
            justify-content: center;
            flex-wrap: nowrap;
            width: 100vw;
            padding: 20px 0;
            flex-direction: column;
            align-items: center;

            @include mq(sm) {
                align-items: flex-start;;
                padding: 2vw 0;
                flex-direction: row;
            }
        }

        &__col {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            margin-bottom: 40px;

            @include mq(sm) {
                width: 25%;
            }
        }

        &__icon {
            width: 100px;
            margin-bottom: 10px;

            @include mq(sm) {
                width: 6vw;
                margin-bottom: 2vw;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__text {
            color: $black;
            text-transform: uppercase;
            font-weight: 700;
            text-align: center;
            font-size: 20px;

            @include mq(sm) {
                font-size: 1.2vw;
            }
        }
    }

    &__historias {
        transform: skewX(-5deg) rotate(-5deg);
        background: $lightestGray;
        padding: 40px 0;
        position: relative;
        z-index: 100;

        @include mq(sm) {
            padding: 4vw 0;
        }

        &__wrapper {
            transform: skewX(5deg) rotate(5deg);
            display: flex;
            flex-wrap: wrap;
            padding: 20px 0;
            justify-content: center;

            @include mq(sm) {
                justify-content: space-between;
                padding: 2vw 8vw 4vw 8vw;
            }
        }

        &__search {
            transform: skewX(5deg) rotate(5deg);
            text-align: center;

            &__wrapper {
                width: 90%;
                max-width: 700px;
                display: inline-flex;
                margin-top: 30px;
                margin-bottom: 15px;
            }

            input {
                height: 40px;
                flex-grow: 2;

                &:focus {
                    margin-right: 20px;
                }
            }

            &__submit {
                width: 40px;
                height: 40px;
                background: $white;
                border:none;
            }
        }

        &__container {
            @include trans;

            background: transparent;
            border-radius: 10px;
            margin-bottom: 20px;
            cursor: pointer;

            @include mq(sm) {
                margin-bottom: 2vw;
            }

            &.-hidden-xs {
                display: none;

                @include mq(sm) {
                    display: block;
                }
            }

            &:hover {
                box-shadow: 0 10px 20px 5px rgba(0,0,0,.15);
                transform: scale3d(1.01,1.01,1.01);

                @include mq(sm) {
                    transform: scale3d(1.1,1.1,1.1);
                }
            }
        }

        &__image {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            overflow: hidden;
            position: relative;
            width: 90vw;
            height: 90vw;

            @include mq(sm) {
                width: 20vw;
                height: 20vw;
            }

            img {
                width: auto;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        &__content {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            background: $white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;

            @include mq(sm) {
                padding: 1vw;
            }
        }

        &__name,
        &__counter {
            color: $black;
            text-transform: uppercase;
            margin: 0;
            font-weight: 700;
            font-size: 16px;

            @include mq(sm) {
                font-size: 1vw;
            }
        }

        &__likes {
            i {
                color: $red;
                margin-left: 10px;

                @include mq(sm) {
                    margin-left: .5vw;
                }
            }
        }

        &__more-button {
            background: transparent;
            border: 2px solid $black;
            color: $black;
            text-transform: uppercase;
            font-weight: 700;
            display: block;
            margin-left: auto;
            margin-right: auto;
            transform: skewX(5deg) rotate(5deg);;
            padding: 10px 60px;
            font-size: 16px;

            @include mq(sm) {
                font-size: 1.3vw;
                padding: 1vw 6vw;
            }
        }
    }

    &__premios {
        background: $red;
        transform: skewX(-5deg) rotate(-5deg);
        padding: 40px 40px 120px;
        margin-bottom: -80px;
        margin-top: -5px;

        @include mq(sm) {
            margin-top: -3px;
            padding: 4vw 0 9vw 0;
        }

        &__wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            transform: skewX(5deg) rotate(5deg);
            flex-direction: column;

            @include mq(sm) {
                flex-direction: row;
            }
        }

        &__col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;

            @include mq(sm) {
                width: 25vw;
            }
        }

        &__number {
            color: $white;
            font-weight: 700;
            font-size: 100px;

            @include mq(sm) {
                font-size: 6vw;
            }
        }

        &__text {
            text-transform: uppercase;
            color: $white;
            font-weight: 700;
            font-size: 20px;
            text-align: center;

            @include mq(sm) {
                font-size: 1vw;
            }
        }

        &__conditions {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transform: skewX(5deg) rotate(5deg);;
            padding: 40px 0 80px 0;

            @include mq(sm) {
                padding: 4vw 0 6vw 0;

            }

            p {
                color: $black;
                text-transform: uppercase;
                margin: 0;
                font-weight: 700;
                font-size: 10px;
                text-align: center;

                @include mq(sm) {
                    font-size: .8vw;

                }
            }
        }
    }

    &__eg-modal {

        &__wrapper {
            padding: 20px 0 0 0;
            width: 100vw;
            height: 80vh;
            position: relative;

            @include mq(sm) {
                padding: 4vw 0;
                height: auto;
                width: auto;
            }

            &--terminos {
                overflow: hidden;
                padding: 3vh 0;

                @include mq(sm) {
                    width: 70%;
                    margin-left: auto;
                    margin-right: auto;
                    overflow: visible;
                }
            }

            .flexrow {
                overflow: auto;
                height: 65vh;

                @include mq(sm) {
                    height: auto;
                    overflow: visible;
                }
            }

            &.-hidden {
                display: none;
            }
        }

        &__group {

            &--image {
                display: block;
                width: 100%;
                margin-left: 0;
                margin-right: 0;

                @include mq(sm) {
                    width: 70%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &--left {
                order: 2;
                height: 10vh;

                @include mq(sm) {
                    order: 1;
                    height: auto;
                }
            }

            &--right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                order: 1;

                @include mq(sm) {
                    order: 2;
                }
            }
        }

        &__image {
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;
            width: 100%;
            height: 90vw;

            @include mq(sm) {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                margin-bottom: 1vw;
            }

            img {
                width: auto;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        &__name {
            margin-top: 0;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 20px;

            @include mq(sm) {
                margin-bottom: 2vw;
                font-size: 2vw;
            }
        }

        &__text {
            margin-bottom: 20px;
            font-size: 18px;

            @include mq(sm) {
                font-size: 1.5vw;
                width: 70%;
                margin-bottom: 2vw;
            }
        }

        &__counter {
            text-align: center;

            i {
                color: $red;
                margin-left: 1vw;
            }
        }

        &__vote-button {
            height: 10vh;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mq(sm) {
                align-items: flex-start;
                justify-content: flex-start;
                height: auto;
            }

            button {
                background: $red;
                border: none;
                padding: 1vw 3vw;
                color: $white;
                text-transform: uppercase;
                font-weight: 700;
            }
        }

        &__content {
            overflow: auto;
            height: 74vh;

            // @include mq(sm) {
            //     height: auto;
            // }

            .home__eg-modal__text {
                font-size: 14px;
                margin-bottom: 5px;
            }
        }
    }
}
